@import "src/styles/colorVars";

.textbox {
    display: flex;
    flex-direction: row;
    border: 1px solid $primary_grey;
    border-radius: 2px;
    width: 100%;
    position: relative;

    &:hover {
        cursor: pointer;
        border-color: $primary_green_hover;
    }
}

.textareaBlocked {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: not-allowed;
}

.textarea {
    border-left: none !important;
    border-bottom: none !important;
    border-right: none !important;
    min-height: 100% !important;
}

.emojiPickerTrigger {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0 15px;

    svg {
        width: 20px;
        height: 20px;
    }
}

.emojiBlocked {
    cursor: not-allowed !important;
}

.emojiPicker {
    position: absolute;
    right: 0;
    z-index: 2;
    bottom: 0;

    &.top {
        top: 0;
        bottom: auto;
    }
    &.bottom {
        bottom: 0;
        top: auto;
    }
}
