@import "src/styles/colorVars";
@import "src/styles/numbers";

.context {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 40px 10px;
  height: calc(100% - 160px);

  @media (max-width: $mobile_width) {
    padding: 0 16px 10px;
  }
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.label {
  display: flex;
  margin: 20px 0 5px;
  justify-content: space-between;

  font-family: SF UI Text;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(56, 60, 58, 0.7);
}

.label:first-child {
  text-transform: capitalize;
}

.editTemplateWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.editTemplateMessageFooter {
  border-top: 2px solid #eaeaea;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 35px;
}

.templateParaminput {
  margin-top: 4px;
}

.templateSelectIcon {
  height: 20px;
  width: 24px;
}

.templateGeneratorSelectBlocked {
  [class="ant-select-selector"] {
    border-color: #d9d9d9 !important;
  }
}

.templateGeneratorTextareaButton {
  border-left: none !important;
  border-bottom: none !important;
  border-right: none !important;
  height: 48px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 8px;

  & > span {
    margin: 0 !important;
  }

  &:hover {
    border-color: #d9d9d9 !important;

    .templateGeneratorAddPlaceholderIcon path {
      fill: $primary_green !important;
    }

    .createNewTemplateButtonText {
      color: $primary_green !important;
    }
  }
}

.templateGeneratorAddPlaceholderIcon {
  position: relative;
  top: 1px;
}

.templateTextbox {
  display: flex;
  flex-direction: row;
}

.templateTextboxBlock {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.templateTextboxActive {
  cursor: pointer;
  border-color: var(--primary-green);
}

.templateEmojiPickerTrigger {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0 15px;

  svg {
      width: 20px;
      height: 20px;
  }
}

.templateEmojiBlocked {
  cursor: not-allowed !important;
}

.templateEmojiPicker {
  right: 80px;
  left: initial;
  bottom: 0px;

  @media (max-width: $mobile_width) {
    right: 0px;
  }
}

.templateGeneratorTextareaBlocked {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: not-allowed;
}

.templateGeneratorDoubleBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.templateGeneratorSingleBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 48%;
  width: 48%;
}

.templateAdvancedSettingsDivider {
  margin: 36px 0 30px !important;
}

.templateEditButtons {
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  background-color: #ffffff;
}

.templateGeneratorBottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 2px solid #eaeaea;
  height: 86px;
  padding: 0 20px;

  @media (max-width: $mobile_width) {
    padding: 0 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      width: auto !important;
      font-size: 13px;
      white-space: normal;
    }
  }
}

.templateGeneratorMessageCounterWrapper {
  display: flex;
  flex-direction: row;
}

.templateGeneratorMessageCounter {
  margin: 0 5px;
  font-family: SF UI Text, "Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(56, 60, 58, 0.7) !important;
}

.templateGeneratorLabelIcon {
  display: flex !important;
  align-items: center;
  cursor: pointer;
}

.createNewTemplateButtonText {
  color: $primary_black;
}

.templateGeneratorLabelTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $primary_black;

  &.rtl {
    text-align: right;
  }
}

.editTemplateMessageButton {
  width: 168px;
  height: 48px !important;
  flex-basis: auto;
  flex-grow: unset;
  margin-left: 24px;

  &.disabled {
    pointer-events: none;
  }
}

.switchButton {
  max-width: 40%;
  min-height: 48px;
}

.sendErrorText {
  color: $primary_red !important;
}

.richParamsInfoMessage {
  margin: 8px 0;
  padding: 8px;
  font-size: 14px;
  background-color: $secondary_green;

  &.rtl {
    direction: rtl;
    text-align: right;

    .info-circle {
      margin-left: 8px;
      margin-right: 0;
    }
  }
}

.button-help-text {
  display: flex;
  align-items: flex-start;
}

.info-text {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  margin: 0;
  color: $primary_black;
}

.info-circle {
  margin-right: 8px;
  margin-left: 0;
  position: relative;
  top: 4px;

  svg {
    fill: $primary_green;
  }
}

.tooltip {
  [class="ant-tooltip-inner"] {
    max-height: 200px;
    overflow-y: auto;
    font-size: 13px;
  }
}

.defaultTemplateMoreInfo {
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
}

.defaultTemplateTooltipWrapper {
  display: flex;
  flex-direction: column;

  & > div:first-child {
    margin-bottom: 8px;
    font-weight: bold;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 0;
    margin-bottom: 8px;
    padding-left: 24px;

    span {
      color: $secondary_grey;
    }
  }
}

.defaultTemplateTooltipOverlay {
  min-width: 300px;
}
