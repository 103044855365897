@import "src/styles/colorVars";
@import "src/styles/numbers";

$list_height: calc(100vh - 65px - 90px); // header height + inputs height

.message-drawer__root {
  width: calc(100vw - #{$sidebar_width});
}

.message-drawer__paperAnchorLeft {
  margin-left: $sidebar_width;
  width: 100%;
  max-width: calc(100vw - #{$sidebar_width});

  @media (max-width: $mobile-width) {
    margin-left: 0px;
    max-width: 100vw;
  }
}

.message-drawer__paperAnchorRight {
  margin-right: $sidebar_width;
  width: 100%;
  max-width: calc(100vw - #{$sidebar_width});

  @media (max-width: $mobile-width) {
    margin-right: 0px;
    max-width: 100vw;
  }
}

.message-drawer__container {
  display: grid;
  grid-template-areas: "header main" "selector main";
  grid-template-columns: 3fr 2fr;
  grid-template-rows: auto 1fr;
  align-items: center;
  height: 100vh;
  overflow-y: hidden;

  &.rtl {
    .reports-selector__inputIcon {
      margin-right: 0;
      margin-left: 8px;
    }
    [class="ant-input-prefix"] {
      margin-right: 0;
    }
    .reports-selector__inputIcon--clear {
      right: auto;
      left: 4px;
    }
    div[class="ant-picker-range-separator"] {
      padding-right: 0;
      padding-left: 8px;
      margin-left: 0;
      margin-right: -20px;
    }
    span[class="ant-picker-suffix"] {
      margin-left: 8px;
      margin-right: 0;
      order: -1;
    }

    [class="ant-picker-range-separator"] svg {
      transform: rotate3d(0, 1, 0, 180deg);
    }
  }
}

.message-drawer__header {
  grid-area: header;
  border-right: 1px solid $primary_grey_border;
}

.message-drawer__selector {
  grid-area: selector;
  border-right: 1px solid $primary_grey_border;
  width: 100%;
  height: 100%;
  padding-bottom: 65px;
}

.message-drawer__main {
  grid-area: main;
  width: 100%;
  height: 100%;
  padding-bottom: 65px;
  overflow-y: auto;
}

.message-drawer__back {
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content;
  justify-self: flex-start;
  font-size: 16px;
  font-weight: 600;
  padding: 32px;
  padding-top: 24px;
  line-height: 45px;

  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  &.rtl {
    svg {
      transform: rotate(180deg);
    }
  }
}

.message-drawer__header {
  display: grid;
  justify-items: center;
  font-size: 20px;
  font-weight: 400;
}

.message-drawer__title {
  margin-left: -12px;
}

.single-report__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 44px 26px 44px 32px;

  &--center {
    align-items: center;
    justify-content: center;
  }
}

.reports-selector__inputIcon--clear {
  width: 28px;
  height: 28px;
  background-color: transparent;
  border-radius: 4px;
  padding: 8px;
  fill: $primary_red;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  left: auto;
  right: 4px;

  &:hover {
    background-color: $secondary_grey;
  }
}

.reports-selector__inputIcon {
  margin-right: 8px;
  margin-left: 0;
  fill: $tertiary_black;

  svg {
    fill: $tertiary_black;
  }
}

.reports-selector__inputsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 18px;
  margin-bottom: 18px;
  padding: 0 72px;

  & > * {
    height: 48px;
  }
}

.reports-selector__inputsWrapper {
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    & > svg {
      visibility: visible;
    }
  }
}

.reports-selector__datePicker {
  height: 100%;
  width: 100%;
  box-shadow: none !important;

  span[class="ant-picker-suffix"] {
    margin-left: 0;
    margin-right: 8px;
    order: -1;
  }

  div[class*="ant-picker-input"] {
    & > input {
      font-size: 13px;

      &::placeholder {
        padding-left: 8px;
      }
    }
  }

  div[class="ant-picker-range-separator"] {
    padding-right: 8px;
    padding-left: 0;
    margin-left: -20px;
  }

  &:hover,
  &:active {
    border-color: $primary_green !important;

    span[class="ant-picker-suffix"] > svg > path {
      fill: $primary_green !important;
    }
  }
}

.reports-selector__datePickerDropdown {
  td[class*="ant-picker-cell-in-view"] {
    &[class*="ant-picker-cell-today"] div[class="ant-picker-cell-inner"] {
      color: $primary_green;

      &:before {
        border-color: $primary_green;
        border-radius: 50%;
      }
    }

    &[class*="ant-picker-cell-in-range"]:before,
    &[class*="ant-picker-cell-range-end"]:not([class*="ant-picker-cell-range-end-single"]):before,
    &[class*="ant-picker-cell-range-start"]:not([class*="ant-picker-cell-range-start-single"]):before {
      background-color: $secondary_grey;
    }

    &[class*="ant-picker-cell-range-hover"] [class*="ant-picker-cell-inner"]:after,
    &[class*="ant-picker-cell-range-hover-start"] [class*="ant-picker-cell-inner"]:after,
    &[class*="ant-picker-cell-range-hover-end"] [class*="ant-picker-cell-inner"]:after {
      border-color: $primary_green !important;
    }

    &[class*="ant-picker-cell-range-end"] [class*="ant-picker-cell-inner"],
    &[class*="ant-picker-cell-range-start"] [class*="ant-picker-cell-inner"],
    &[class*="ant-picker-cell-selected"] [class*="ant-picker-cell-inner"] {
      background-color: $primary_green;
      border-color: $primary_green;
      border-radius: 50% !important;
    }
  }
}

.reports-selector__listContainer {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: $list_height;
  overflow: auto;
  padding: 24px 72px;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 6px, 100% 6px;
  background-attachment: local, local, scroll, scroll;
}

.reports-selector__listItem {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border-bottom: 1px solid $primary_grey_border;
  gap: 16px;
  padding: 8px 16px;
  height: 62px;
  min-height: 62px;
  font-weight: 400;
  color: $primary_black;
  cursor: pointer;

  &--selected,
  &:hover {
    background-color: $primary_grey_bg;
  }
}

.reports-selector__listIcon {
  width: 20px;
  height: 20px;
  border: 1.5px solid $tertiary_black;
  border-radius: 50%;

  path {
    fill: $tertiary_black;
  }

  &--selected {
    border-color: $primary_green;

    path {
      fill: $primary_green;
    }
  }
}

.reports-selector__clockIcon {
  width: 20px;
  height: 20px;
}

.reports-selector__titleContainer {
  display: flex;
  flex-direction: column;
  min-width: 0;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--selected {
    font-weight: bold;
  }
}

.reports-selector__listItemTitle {
  font-size: 14px;
  line-height: 20px;
}

.reports-selector__listItemSubTitle {
  font-size: 11px;
  line-height: 16px;
}

.reports-selector__listItemDate {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $tertiary_black;
  margin-left: 8px;
}

.report-selector__loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $list_height;
  position: relative;
}

.reports-selector__noReports {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: $list_height;
  margin-bottom: 20%;
  gap: 16px;

  &--title {
    color: $secondary_black;
  }

  &--button {
    color: $primary_green !important;
    border-color: $primary_green !important;
    height: 32px !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.single-report__title {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 4px !important;
  position: relative;

  textarea {
    border: 1px solid $primary_green !important;
  }

  svg {
    fill: $primary_green !important;
  }

  &.rtl {
    left: auto;
    right: -12px;
  }
}

.single-report__subTitle {
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 4px;
}

.single-report__date {
  display: flex;
  align-items: center;
  font-size: 9px;
  color: $tertiary_black;
  line-height: 16px;
  margin-bottom: 12px;
}

.single-report__titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  border-bottom: 1px solid $primary_grey_border;
  position: relative;
  margin-bottom: 24px;
  min-width: 0;
  height: 72px;
}

.single-report__titleContentWrapper {
  width: calc(100% - 28px);

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.single-report__showMoreButton {
  margin-top: 8px;

  svg {
    width: 28px;
    height: 28px;
  }
}

.single-report__greenBorder {
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 2px;
  width: 56px;
  background-color: $primary_green;

  &.rtl {
    left: auto;
    right: 0;
  }
}

.single-report__infoLine {
  display: flex;
  flex-direction: row;
  position: relative;
  background: $primary_white;
  justify-content: space-between;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: 0.4rem;
    width: 100%;
    height: 0;
    border-bottom: 2px dotted $primary_black;
    opacity: 0.3;
  }

  svg {
    position: absolute;
    left: -20px;
    top: 4px;
  }

  &--not-clickable {
    svg {
      visibility: hidden;
    }
  }

  &:not(&--not-clickable):hover {
    cursor: pointer;
    font-weight: bold;

    svg {
      transform: scale(1.05);
    }
  }

  &--reactions {
    width: fit-content;

    &:before {
      content: none;
    }

    &:hover {
      transform: scale(1.05);
      transform-origin: left center;
    }
  }

  &--scheduled {
    margin-bottom: 16px;
  }
}

.single-report__infoLineText {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  background: inherit;
  position: relative;
  line-height: 24px;
}

.single-report__infoLineText--value {
  display: inline-flex;
  align-items: center;
  direction: ltr;
}

.infoLineText--bold {
  font-weight: bold;
}

.infoLineText--positive {
  color: $primary_green;
}

.infoLineText--negative {
  color: $primary_red;
}

.infoLineText--spacing {
  letter-spacing: -1px;
}

.single-report__infoContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;

  &--no-margin {
    margin-bottom: 0;
  }

  &--scheduled {
    opacity: 0.5;
    user-select: none;
  }
}

.single-report__reactionsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.single-report__reactionsTitle {
  font-size: 12px;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.single-report__commentContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.single-report__commentLabel {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #383c3a;
}

.single-report__commentTextArea {
  margin-bottom: 8px !important;

  &[readonly] {
    background-color: $primary_grey_bg !important;
    border-color: transparent !important;
  }
}

.single-report__commentButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.single-report__commentButton {
  min-width: 100px;
  height: 40px !important;
  min-height: 40px !important;
  display: flex !important;
  border-radius: 4px !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &--defaultButton {
    &:hover {
      color: $primary_green !important;
    }
  }

  &--primaryButton {
    &:hover, &:focus {
      color: $primary_white !important;
      filter: brightness(0.95);
    }
  }
}

.single-report__contentContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  align-items: center;

  div[class="g2-tooltip"] {
    min-width: 148px !important;
  }
}

.single-report__infoLineText__colorBox {
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.single-report__enterIcon {
  position: absolute;
  right: 64px;
  top: 24px;
  color: $primary_green !important;

  &.rtl {
    right: auto;
    left: 64px;
  }
}

.reports-selector_scheduledLabel {
  font-weight: bold;
  color: $primary_green;
  margin-right: 2px;
}

.bulkPercentageNote {
  font-size: 0.8em;
  text-align: right;
  margin-top: -5px;
}

.reports-selector__container {
  display: grid;
  grid-template-rows: auto 1fr;
}

.download-all {
  &__loading--icon {
    svg {
      height: 16px;
      width: 16px;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}
