@import "src/styles/colorVars";

.buttonsBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    gap: 5px;

}

.button {
    background: var(--background-color, $secondary_green_bg);
    border-radius: 8px;
    border: none;
    padding: 5px 10px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);

    letter-spacing: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    word-wrap: break-word;
    white-space: nowrap;
    margin-top: 4px;
    margin-bottom: 0;
}

.error {
    border: 1px solid $primary_red;
}

.text {
    margin: 0;
    color: #ffffff;
}
